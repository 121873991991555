import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"


const IndexPage = () => {

  useEffect(() => {
    navigate("/dashboard/school-updates")
  }, [])


  return (
    <Layout>
      {/* <Head title="Home" />
      <h1>Welcome to the Tigers Club Dashboard</h1>
      <Link to="/dashboard">Login To Your Account</Link> */}
    </Layout>
  )
}

export default IndexPage
